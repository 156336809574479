/* @import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lora&family=Poppins&display=swap'); */
@font-face {
  font-family: 'GetShow';
  src: url('./fonts/GetShow.ttf') format('truetype'), /* TrueType font */
       url('./fonts/GetShow.otf') format('opentype'); /* OpenType font */
}

/* body *, ::after, ::before {
  box-sizing:content-box;
} */
* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #a0a0a0; */
  overflow-x: hidden;
  /* height: 7000px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.HomeContainer {
  width: 100%;
}

.Navbar {
  z-index: 5; 
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  background-color: #333;
  height: 0px;
}

/* .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

} */
.header-mobile {
  width: 100vw;
}

.navOpen .header-mobile {
  height: 1100px;
}

.header-mobile .nav-navbar {
  width: 100vw;
  justify-content: space-between;
  position: relative;
  height: 120px;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.2);
}

.navOpen ul .navItem a {
  font-size: 36px;
  padding: 20px;
}

.header-mobile .navOpen {
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.867);
  z-index: 1000;
}

.nav-navbar .content-container {
  display: flex;
  justify-content: center;
  color: white;
  /* font-weight: 600; */
}

.header-mobile .navOpen {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.header-mobile .content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100vw;
}

.header-mobile .nav-navbar .content-container img {
  scale: 0.8;
}

.header-mobile .navOpen .content-container {
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  height: auto;
  width: calc(100vw - 2rem);
}

.mobileNavToggle {
  display: none;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  color: #333;
}

.mobileNavToggle:hover {
  color: #BC8D4B;
}

.desktopNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0px;
}

.mobileNav {
  position: relative;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
  top: -5%;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileNav .navItem {
  position: relative;
  margin: 20px 0px;
  font-size: 38px;
  font-weight: 500;
  width: fit-content;
  border: 3px solid #fff;
  color: #000;
  min-width: 200px;
  text-align: center;
}

.mobileNav .navItem:hover {
  border: 3px solid#BC8D4B;
  transition: 0.5s;
}

.navItem {
  list-style: none;
  margin: 0 1rem;
}

.navItem a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 2vw;
}

.navItem a:hover {
  color: #BC8D4B;
  /* text-decoration: underline;
  text-underline-offset: 6px; */
  transition: 0.5s;
}

@media (min-width: 1320px) {
  .navItem a {
    font-size: 1.1vw;
  }
}

.header-desktop .content-container {
  position: relative;
  width: calc(100% - 4rem);
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem;
}

.header-desktop .content-container p {
  width: 300px;
}

.header-desktop .content-container .address {
  text-align: left;
  margin-top: 2rem;
}

.header-desktop .content-container .language-container {
  margin-top: 2rem;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header-desktop .content-container .phone {
  text-align: end;
  margin-left: 15px;
  width: fit-content;
  text-align: start;
}

.navScroll {
  background-color: #1a1a1ad0;
  position: fixed;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  top: 0px;
  width: 100vw;
  height: 50px;
}

.hamburger {
  color: white;
  background-color: white;
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7; 
}

.hamburger.is-active:hover {
  opacity: 0.7; 
}

.hamburger .hamburger-inner,
.hamburger .hamburger-inner::before,
.hamburger .hamburger-inner::after {
  background-color: white; 
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; 
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; 
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; 
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px; 
}

.hamburger-inner::after {
  bottom: -10px; 
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; 
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; 
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); 
}


.language-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


















/* Language Selector */




.language-dropdown {
  position: relative;
  display: inline-block;
  text-align: center;
}

.dropdown-button {
  background-color: #0d0d0d91;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.dropdown-icon {
  margin-left: 0.25rem;
}

.dropdown-icon.up::before {
  content: '\25B2';
}

.dropdown-icon.down::before {
  content: '\25BC';
}

.language-dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  z-index: 1;
}

.language-dropdown.visible .dropdown-content {
  display: block;
}

.language-dropdown .dropdown-content button {
  padding: 8px 12px;
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.language-dropdown .dropdown-content button.active {
  background-color: #007bff;
  color: #fff;
}



























/* Carousel */

.fullscreen-slider {
  z-index: 1; /* Keep the z-index value as 1 for the slider */
  top: 0px;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;



}


.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.0);
  color: white;
  padding: 30px 20px;
  border: none;
  cursor: pointer;
  z-index: 3;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.carousel-icon {
  color: rgba(255, 255, 255, 0.826);
  scale: 5;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.caption {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding: 40px 20px;
  border: none;
  width: 80vw;
  height: auto;
  z-index: 2;
}

.caption h1 {
  font-size: 6vw;
  text-align: center;
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 900;
  text-shadow: 5px 5px 20px rgba(0, 0, 0, 0.9);


}

.caption p {
  margin: 20px;
  font-size: 1.9vw;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 700;



}

.reserve-button {
  font-size: 24px;
  margin: 10px;
  color: white;
  background-color: transparent;
  border: 3px solid white;
  padding: 10px;
  text-decoration: none;
  transition: 0.4s;
  cursor: pointer;
}


.reserve-button:hover {
  border: 3px solid #BC8D4B;
  background-color: #BC8D4B;
}














/* Feature.js */




.feature-section {
  width: 100vw;
  height: auto;
  padding-top: 72px;
  padding-bottom: 72px;
  
}

.feature-link {
  transition: color 0.3s cubic-bezier(0.77, 0, 0.175, 1), text-decoration 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  color: white;
  text-decoration: none;
}

.feature-section .feature-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.feature-container .feature-item {
  width: 350px;
  height: 250px;
  margin: 15px;
  display: flex;
  align-items: flex-end;
  background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
  transition: background-size 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.feature-container .feature-item:hover {
  background-size: 125%;
  animation: zoom-in 1s;
}

.feature-container .feature-item:not(:hover) {
  animation: zoom-out 1s
}


@media (max-width: 1199px) {
  .feature-container .feature-item {
    width: 280px;
    height: 250px;
  }
}

@media (max-width: 991px) {
  .feature-container .feature-item {
    width: 210px;
    height: 250px;
  }
}

@media (max-width: 767px) {
  .feature-container .feature-item {
    height: 250px;
  }
}



@media (max-width: 568px) {
  .feature-container .feature-item {
    width: 86vw;
    height: 250px;
  }
}


@keyframes zoom-in {
  0% {
    background-size: 100%;
  }

  100% {
    background-size: 125%;
  }
}


@keyframes zoom-out {
  0% {
    background-size: 125%;
  }

  100% {
    background-size: 100%;
  }
}


/* .feature-link .feature-item.gallery {
	background-size: 100% 100%;
} */

/* @media (max-width: 1199px) {
  
  .feature-link .feature-item.gallery:hover {
    background-size: 125% 125%;
    animation: zoom-in-vertical 1s;
  }
  
  .feature-link .feature-item.gallery:not(:hover) {
    background-size: 100% 100%;
    animation: zoom-out-vertical 1s
  }
} */


@keyframes zoom-in-vertical {
  0% {
    background-size:100% 100%;
  }

  100% {
    background-size: 125% 125%;
  }
}


@keyframes zoom-out-vertical {
  0% {
    background-size: 125% 125%;
  }

  100% {
    background-size:100% 100%;
  }
}





.feature-item .item-content {
  margin: 20px;
  padding: 10px;
}

.feature-link:hover .item-content {
  background-color: rgba(0, 0, 0, 0.709);
}


.feature-item .item-content h2 {
  /* font-family: 'Inria Serif', serif;
  font-family: 'Italiana', serif;
  font-family: 'Poppins', sans-serif; */
  font-size: 24px;
  color: white;
  transition: 1s;
}

.feature-item .item-content .item-desc {
  /* font-family: 'Inria Serif', serif;
  font-family: 'Italiana', serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Lora', serif; */
  font-style: italic;
  font-size: 16px;
  font-weight: 250;
  color: white;
  transition: 1s;
}

.feature-link:hover .item-title, .feature-link:hover .item-desc{
  color: white;
  /* text-decoration: underline; */
}










/* About.js */


.about-section {
  width: 100vw;
}

.about-section .about-container {
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  box-shadow: 3px 5px 35px rgba(42, 42, 42, 0.222);
}

.about-container .about-content {
  width: 65%;
  padding: 54px;
  display: flex;
  flex-direction: column;

}

.about-container .image-container {
  width: 35%;
  position: relative;


}

.about-container .about-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/about%2Fcafecolaofrente.jpg?alt=media&token=ae2a2dec-7a85-48b3-a544-b073cacf8783);
}

.about-content .text-container {
  display: flex;
  flex-direction: row;


}

.about-content .subtitle {
  font-size: 16px;
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 100;
  letter-spacing: 1px;
}

.about-content .heading {
  position: relative;
  display: inline-block;
  font-size: 54px;
  font-weight: 600;
  /* font-family: 'Poppins', sans-serif; */
  margin: 0px 0px 24px;
  letter-spacing: 1px;
  line-height: 1.2em;
  width: fit-content;
  /* text-decoration: underline;
  text-underline-offset: 10px; */
}

.about-content .heading::before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: black;
}

.about-content .heading::after {
  content: '';
  width: 100%;
  height: 5px;
  bottom: -7px;
  left: 0px;
  position: absolute;
  background-color: black;
}

.about-content .title {
  font-size: 24px;
  /* font-family: 'Lora', serif; */
  font-style: italic;
  margin: 0px 0px 24px;
}

.about-container .text-container p {
  width: 100%;
  min-width: 200px;
  padding: 0px 15px;
  margin: 0px 0px 24px;
  font-size: 16px;
  /* font-family: 'Lora', serif; */
  line-height:28px;


}

.about-container .link {
  margin: 36px 0px 0px;
  padding: 10px 24px;
  font-size: 14px;
  font-family: sans-serif;
  border: 4px solid black;
  width:fit-content;
  text-align: center;
  text-decoration: none;
  color: black;
}


@media (max-width: 500px) {
  .about-content .heading {
    position: relative;
    display: inline-block;
    font-size: 44px;
    font-weight: 600;
    /* font-family: 'Poppins', sans-serif; */
    margin: 0px 0px 24px;
    letter-spacing: 1px;
    line-height: 1.2em;
    width: fit-content;
    /* text-decoration: underline;
    text-underline-offset: 10px; */
  }
}








/* Hours.js */


.hours-section {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/parallax%2Ffoodparallax.jpg?alt=media&token=f1fd79ad-8da9-46de-8d7b-829cb66a8ddb);
  position: relative;
  box-shadow: 3px 5px 35px rgba(47, 47, 48, 0.442);
}

.parallax,
.no-parallax {
	background-repeat: repeat;
	background-position: center center !important;
  background-attachment: scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

}

.parallax {
	position: relative;
	min-height: 300px;
	padding: 108px 0;
}

@media (min-width: 1200px) {
  .parallax {
    background-attachment: fixed !important;
  }
}



.hours-section .parallax-content {
  position: relative;
  padding: 50px;
  color: white;



}

.parallax .hours-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  background-color: #00000045;
  width: 90vw;
  padding: 50px 10px;
}

.hours-container .subtitle {
  font-size: 16px;
  /* font-family: 'Poppins', sans-serif; */
}

.hours-container .title {
  position: relative;
  display: inline-block;
  font-size: 54px;
  font-weight: 600;
  /* font-family: 'Poppins', sans-serif; */
  margin: 0px 0px 24px;
  letter-spacing: 1px;
  line-height: 1.2em;
  width: fit-content;
  text-align: center;
}


.hours-container .title::before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: white;
}

.hours-container .title::after {
  content: '';
  width: 100%;
  height: 5px;
  bottom: -7px;
  left: 0px;
  position: absolute;
  background-color: white;
}

.hours-container .hours p {
  margin: 0px 0px 24px;
}












/* Footer.js */



.footer {
  padding: 72px 0px 0px 0px;
}


.footer .footer-container {
  display: flex;
  flex-direction: column;
}

.footer-container div.divider {
  border-bottom: 1px solid rgb(221, 221, 221) !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.footer .footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;


}


.footer-container .footer-section {
  width: 30vw;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 24px;

}


.footer-section h5 {
  font-size: 24px;
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;
}

.footer-section p {
  text-align: center;
  font-size: 16px;
  /* font-family: 'Lora', serif; */
  line-height: 35px;
}

.footer-container .footer-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  /* margin: 0px; */
  padding: 0px;
}

.footer-social li a.social-item {
  font-size: 24px;
  text-decoration: none;
  color: #000;
  padding: 4px 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.207);
  text-shadow: 5px 5px 20px rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  line-height: 1;
  text-rendering: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}


.footer-container .sub-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.footer-container .sub-footer > * {
  width: 33%;
  text-align: center;
}

.footer-payments {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-payments .payment-methods {
  padding-top: 10px;
}

.footer-payments img {
  width: 37px;
}


.footer-container .created {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8b8b8b43;
}

.footer-container .created p {
 margin: 0px;
 color: #888787;
}

.footer-container .created p a {
  color: #888787;
}







/* Hero.js Section */


.hero-section {
  display: flex;
  justify-content: center;
}

.hero-section .overlay {
  height: 100%;
}

.hero-section .hero-image {
  position: relative;
  background-image: url("https://adventor.wpengine.com/wp-content/uploads/2021/07/slider-1.jpg");
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.spacer-container {
  position: relative;
  height: 300px;
  width: 100%;
  top: 120px;
}


.spacer {
  position: relative;
  aspect-ratio: 360/408;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  position: absolute;
  background-image: url('./images/divider.svg');
  z-index: 2;
}

.hero-section .caption {
  z-index: 1;
  position: absolute;
}

.hero-section .caption h1 {
  font-family: 'GetShow', sans-serif;
  font-size: 102px;
  font-weight: 100;
}

@media (max-width: 991px) {
  .hero-section .caption h1 {
    font-size: 80px;
  }
}

@media screen and (max-width: 768px) and (max-height: 600px) {
  .hero-section .caption h1 {
    font-size: 62px;
  }
}

.hero-section .caption p {
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 300;
}


@media (max-height: 600px) {
  .hero-section .caption{
    margin-top: 30px;
    padding-bottom: 0px;
  }

  .hero-section .caption p {
    font-size: 16px;
  }
}

.scroll-down {
  position: relative;
  top: 15vh;
}

@media (max-height: 600px) {
  .scroll-down {
    top: 3vh;
  }
}


.scroll-down:before,
.scroll-down:after {
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	transform: rotate(45deg);
	border-bottom: 4px solid white;
	border-right: 4px solid white;
}

.scroll-down:before {
	animation: down-arrow-before 2.6s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.scroll-down:after {
	animation: down-arrow-after 2.6s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@keyframes down-arrow-before {
	50% {
		transform: rotate(45deg) translate(70%, 70%);
	}
	100% {
		transform: rotate(45deg) translate(70%, 70%);
	}
}

@keyframes down-arrow-after {
	50% {
		transform: rotate(45deg) translate(110%, 110%);
		opacity: 0;
	}
	51% {
		transform: rotate(45deg) translate(-130%, -130%);
	}
	100% {
		transform: rotate(45deg) translate(-70%, -70%);
		opacity: 1;
	}
}




















/* Events.js */



section.events-section {
  padding-top: 100px;
  padding: 30px;
}

.events-section .events-header h2 {
  color: #2d373c;
  font-size: 35px;
  font-weight: 800;
  line-height: 42px;
}

.events-section .events-header p {
  color: #666;
  font-size: 16px;
  padding-top: 10px;
}


.events-section .events-container {
  position: relative;
  padding-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-evenly;
}














/* EventCard.js */



.eventCard {
  max-width: 33.3333333333%;
  min-width: 350px;
  border-radius: 10px;
  box-shadow: 3px 5px 35px rgba(86,68,169,.5);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.eventCard .card-container {
  height: 100%;
}

.card-thumbnail {
  position: relative;
}

.card-thumbnail a img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-label {
  background: #ff4838;
  border-radius: 3px;
  bottom: 0;
  -webkit-clip-path: polygon(9% 0,90% 3%,100% 94%,0 100%);
  clip-path: polygon(9% 0,90% 3%,100% 94%,0 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  left: 20px;
  padding: 9px 32px;
  position: absolute;
  text-transform: capitalize;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.card-label .card-icon {
  margin-right: 10px;
}


.eventCard .card-body {
  padding: 30px 25px;  
  width: 100%;
  height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body h3 {
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
}

.card-body h3 a {
  text-decoration: none;
  color: black;
}


.card-bottom {
  align-items: center;
  display: flex;
  /* justify-content: space-between; */
  margin-top: 0px;
  width: 100%;
}

.book-button a {
  background: #fff;
    border: 1px solid #ff4838;
    border-radius: 5px;
    box-sizing: border-box;
    color: #ff4838;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 16px;
    text-transform: uppercase;
    transition: all .3s;
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    margin-right: 40px;
}

.book-button a:hover {
  background-color: #ff4838;
  color: #fff;
}

.book-button a .arrow-icon {
  padding-left: 10px;
  font-size: 40px;
}

.card-bottom .card-info {
  width: 40%;
}

.card-bottom .card-info span {
  color: #2d373c;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.card-bottom .card-info h6 {
  color: #ff4838;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
}

.card-bottom .card-info h6 .currency {
  color: #ff4838;
  font-size: 10px;
  font-weight: 800;
  line-height: 1;
}


@media (min-width: 1200px) {

  .eventCard {
    max-width: 30.3333333333%;
    min-width: 150px;
  }
}


@media (max-width: 1199px) {

  .eventCard {
    min-width: 48%;
  }
}



@media (max-width: 400px) {

  .eventCard .card-body {
    height:auto;
  }

  .card-bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .card-bottom .book-button {
    width: 100%;
    margin-top: 15px;
  }

  .book-button a {
    justify-content: center;
    margin-right: 0px;
  }
}














@media screen and (max-width: 767px) {
  .fullscreen-slider {
    height: 100vh;
  }

  .caption h1 {
    font-size: 50px;
  }

  .caption p {
    margin: 20px;
    font-size: 24px;
  }

  .about-section .about-container {
    flex-wrap: wrap;
  }

  .about-container .about-content {
    width: 100%;
  }

  .about-container .image-container {
    width: 100%;
    height: 500px;
  }

  .about-content .text-container {
    flex-wrap: wrap;
  }

  .about-container .text-container p {
    padding-left: 0px;
  }

  .hours-section .parallax-content {
    padding: 0px;
  }

  .footer .footer-content {
    flex-direction: column;
  }

  .footer-container .footer-section {
    width: 80vw;
  }

  section.events-section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .events-section  .events-container {
    justify-content: center;
  }

  .eventCard {
    min-width: 90vw;
  }
}




@media screen and (max-width: 340px) {

  .caption h1 {
    font-size: 40px;
  }

  .caption p {
    margin: 20px;
    font-size: 18px;
  }
}












/* Activity Detail */




.activityDetail {
  display: flex;
  /* width: 100vw; */
  height: 100vh;
  justify-content: center;
  /* margin-top: 200px; */
}

.activity-content {
  width: calc(1140px - 40px);
  height: 100vh;
  padding: 20px 20px 20px 20px;
}

.activity-content .backNav {
  /* margin-bottom: 20px; */
}

.activity-content .backNav a {
  width: fit-content;
  font-weight: 600;
  padding: 7px;
  border-radius: 10px;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.289);
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #ff4838;
  transition: 0.2s;
}

.activity-content .backNav a:hover {
  background-color: #ff4838;
  color: #FFF;
}

.activity-content .backNav a > * {
  padding-right: 10px;
  font-weight: 800;
  font-size: 20px;
}


.photo-array {
  display: grid;
  grid-gap: 0.5em;
  grid-template-areas:
    "photoOne photoTwo photoThree"
    "photoOne photoFour photoFive";
  grid-template-columns: 1fr 20% 20%;
}

.photo-array div {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.photo-array div:first-child {
  grid-area: photoOne;
  height: 450px;
  border-radius: 10px 0px 0px 10px;
}

.photo-array div:nth-child(3) {
  border-radius: 0px 10px 0px 0px;
}

.photo-array div:nth-child(5) {
  border-radius: 0px 0px 10px 0px;
}

.photo-array div .view-all {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #0000006a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 10px 0px;
}

.photo-array div .view-all:hover {
  background-color: #000000b4;
  cursor: pointer;
}

.photo-array div .view-all > * {
  padding: 10px;
}

.activity-content .fullscreen-slider {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000da;
  z-index: 2;
}

.activity-content .fullscreen-slider .slide {
  object-fit: contain;
}

.activity-content .fullscreen-slider .overlay {
  background-color: rgba(0, 0, 0, 0.0);
}

.close-full {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  font-size: 32px;
  background-color: rgba(0, 0, 0, 0.639);
  border: 0px solid;
  border-radius: 5px;
  margin: 10px;
  color: white;
  z-index: 2;
}

.close-full:hover {
  cursor: pointer;
}

.activity-content .mobile-carousel {
  display: none;
}

@media (max-width: 591px) {
  .activity-content {
   width: 100vw;
   height: 100vh;
  }

  .photo-array {
    display: none;
  }

  .activity-content .fullscreen-slider {
    border-radius: 10px;
  }

  .activity-content .mobile-carousel {
    display: flex;
    justify-content: center;
  }
  
  .activity-content .mobile-carousel .fullscreen-slider {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #000;
    display: block;
  }
  
  .activity-content .mobile-carousel .fullscreen-slider .slide {
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }
 }


 .activity-content h1 {
  color: #2d373c;
  font-size: 1.9rem;
  font-weight: 800;
  line-height: 42px;
  margin: 20px 0px 30px 0px;
 }


 .desc-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
 }

 .desc-cards .desc-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.289);
  padding: 10px;
  gap: 10px;
  margin-bottom: 15px;
 }

 .desc-cards .desc-card svg {
    color: #ff4838;
    width: 20px;
    height: 20px;
 }

 .desc-card .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #2d373c;
  line-height: 22px;
 }

.desc-card .text h3 {
  font-size: 16px;
  font-weight: 700;
 }

 .desc-card .text .title {
  font-size: 16px;
  font-weight: 700;
 }

 .desc-card .text p {
  font-size: 16px;
  margin-bottom: 0px;
 }
















 /* Date Picker */


 .date-picker {
  position: relative;
  text-align: center;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.date-picker h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.date-options {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date-option {
  font-size: 1rem;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #ccc;
  color: #000;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.date-option:hover {
  background-color: #ff4838;
  color: #fff;
}

.date-option[selected="true"] {
  background-color: #ff4838;
  color: #fff;
}

.date-option.unavailable {
  background-color: #76757548;
  color: #6666668a;
}

.day {
  font-size: 1rem;
  margin-bottom: 5px;
}

.date {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.month {
  font-size: 1rem;
  margin-top: 5px;
}


.current-date {
  border: 2px solid #ff4838;
  background-color: #ff493821;
}











/* Full Calendar Picker */


#fullCal.date-picker {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#fullCal.date-picker h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

#fullCal.date-picker .show-full-calendar {
  padding: 10px;
  background-color: #ff4838;
  border-radius: 5px;
  border: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  color: #2d373c;
  color: #fff;
}

#fullCal.date-picker .calendar {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: 0 auto;
  background-color: #f7f7f7;
}

#fullCal.date-picker .month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff4838;
  color: #fff;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

#fullCal.date-picker.prev-month,
#fullCal.date-picker.next-month {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
}

#fullCal.date-picker .current-month {
  font-size: 1rem;
}

#fullCal.date-picker .days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

#fullCal.date-picker .date-button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.2s, color 0.2s;
}

#fullCal.date-picker .date-button.disabled {
  background-color: #35353534;
  text-decoration: line-through;
}

#fullCal.date-picker .date-button.disabled:hover {
  cursor: not-allowed;
}

#fullCal.date-picker .date-button:hover {
  background-color: #ff4838;
  color: #fff;
}

#fullCal.date-picker .date-button.selected {
  background-color: #ff4838;
  color: #fff;
}

#fullCal.date-picker .selected-date {
  margin-top: 10px;
}


.date-picker-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.date-picker button.next-button {
  position: relative;
  left: -1px;
  top: 30px;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #ccc;
  color: #000;
  border-radius: 5px;
  transition: background-color 0.5s, color 0.5s;
}


.date-picker button.next-button.disabled {
  background-color: #7c7a7a58;
  color: #0000008a;
  cursor: not-allowed;
}

















/* TimePicker.js */

.time-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.time-options li {
  list-style: none;
  width: fit-content;
}

.time-options li button.time-option {
  font-size: 22px;
  width: 130px;
  margin: 4px;
  padding: 3px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #ccc;
  color: #2d373c;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.time-options li button.time-option p {
  font-size: 12px;
}

.time-options .time-option:hover {
  border: 2px solid #ff4838;
}


.time-options .time-option.selected {
  border: 2px solid #ff4838;
  background-color: #ff493821;
}

.time-options .time-option.unavailable {
  background-color: #76757548;
  color: #6666668a;
}






















/* BookingPage.js */


.booking-section {
  position: relative;
  display: flex;
  width: 100vw;
  justify-content: center;


}

.booking-section .booking-container {
  position: relative;
  width: 1140px;
  height: 100vh;
  padding: 0px 32px;
}

.booking-container .info-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.booking-container .booking-steps {
  display: flex;
  align-items: center;
  /* gap: 10px; */
  font-size: 20px;
  margin-top: 20px;
  width: 100%;
}

.booking-container .booking-steps a {
  width: fit-content;
  text-wrap: nowrap;
  font-weight: 600;
  padding: 7px;
  border-radius: 10px;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.289);
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #ff4838;
  transition: 0.2s;
  font-size: 15px;
}



.booking-container .booking-steps svg {
  width: 40px;
  font-size: 25px;
}

.booking-container .booking-steps span {
  position: relative;
  width: 100%;
  right: 30px;
  text-align: center;
}

.booking-container h2 {
  color: #2d373c;
  margin-top: 20px;
  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
}

.booking-container form .input-container {
  display: flex;
  flex-direction: column;
  width: 345px;
  margin: 0px 0px 8px;
}

.booking-container form .input-container.fourwheels {
  text-align: center;
}

.booking-container form .input-container.fourwheels label {
  padding-bottom: 10px;
  font-size: 18px;
}

.booking-container form .fourwheels-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  gap: 13px;
}

.booking-container form .fourwheels-counter button {
  position: relative;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #ff4838;
  border: 2px solid #ccc;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.5s, color 0.5s;
}

.info-container form button.submit-form {
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  background-color: #ff4838;
  border: 2px solid #ccc;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.5s, color 0.5s;
}

.info-container form button.submit-form.disabled {
  background-color: #28262663;
  color: #eaeaea;
  cursor: default;
}

.booking-container form .input-container input {
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 26px;
}

.booking-container form .input-container label {
  padding: 0px 0px 4px;
}

.booking-container form .input-container label.terms-check {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  gap: 3px;
  margin-top: 10px;
}

.booking-container form .input-container label.terms-check input {
  margin-right: 10px;
}

.booking-container form .input-container label span {
  color: red;
}

@media (max-width: 991px) {
  .booking-container .info-container {
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .booking-container .info-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .booking-container .info-container form {
    width: 100%;
  }
}

@media (max-width: 767px) {


  .booking-container form .input-container {
    width: 100%;
  }
}















/* BookingSummary.js */



.booking-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.397);
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 100px;
}

.summary-header {
  width: 385px;
  max-width: 385px;
  height: 30%;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  border-radius: 10px 10px 0px 0px;
}

.reservation-summary .summary-header {
  height: 250px;
}

.summary-header .overlay {
  position: relative;
  border-radius: 10px 10px 0px 0px;
}

.summary-header .overlay h3 {
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  padding: 20px;
}

.summary-info {
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  max-width: 345px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0px;
  text-align: center;
}

.summary-info .details-title {
  text-align: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}

.summary-info h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
}

.summary-info div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.summary-info div.divider {
  border-bottom: 1px solid rgb(221, 221, 221) !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.summary-info div.location {
  flex-direction: column;
}

.summary-info div span {
  color: #000;
  font-weight: 600;
}

.summary-info .total-price {
  width: 100%;
  font-size: 22px;
  text-align: center;
  align-items: center;
}

.summary-info .total-price span {
  font-size: 28px;
}

@media (max-width: 991px) {
  .summary-info {
    max-width: 285px;
  }

  .summary-info .total-price span {
    font-size: 22px;
  }

  .summary-header {
    max-width: 325px;
  }
}

@media (max-width: 767px) {
  .booking-summary {
    margin-bottom: 20px;
  }

  .summary-info {
    width: calc(100% - 40px);
    max-width: none;
  }

  .summary-header {
    width: 100%;
    max-width: none;
  }

  .summary-header .overlay h3 {
    margin: 0px;
  }
}

@media (max-width: 568px) {
  .summary-info {
    justify-content: center;
  }
}





















:root {
  --primary-color: #0077cc;
  --secondary-color: #66b3ff;
  --background-light: #fff;
  --background-light-secondary: #ddd;
  --background-dark: #333;
  --background-dark-secondary: #c5c4c4;
  --primary-dark-hover: #0077cc;
  --secondary-hover: #0062a2;
  --primary-light-hover: #66b3ff;
  --secondary-light-hover: #fff;
  --blocked-color: #8b8b8bb5;
  --range-color: #98b8e45e;
  --danger-color: #cc0000;
  --danger-color-hover: #a30000;
  --font-color: #333;
  --font-family: "Helvetica Neue", sans-serif;
  --font-size: 16px;
  --line-height: 1.5;
  --border-radius: 4px;
  --max-width: 1200px;
}
/* Login.js */

.card-main-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2%;
  background-color: var(--background-light-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.card {
  width: 40%;
  min-height: 80%;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  overflow: auto;
}

.card.signup-card {
  overflow: hidden;
}

.card-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.login-card,
.signup-card,
.welcome-card {
  flex: 1;
  overflow: hidden;
}

.card-body {
  width: 75%;
}

.card h4 {
  font-size: 5vh;
  padding-top: 10vh;
  margin-bottom: 5vh;
}

.login-title, .signup-title {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-thickness: 0.5vh;
  text-underline-offset: 0.5vh;
}

h4.signup-title {
  padding-top: 5vh;
}

form {
  display: flex;
  flex-direction: column;
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.nameContainer .form-input.firstName {
  margin-right: 10px;
}

.nameContainer .form-input.lastName {
  margin-left: 10px;
}

hr {
  margin-bottom: 20px;
  margin-top: 10px;
}

.signup-button.alt {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  background-color: transparent;
  text-decoration: none;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-button.alt:hover {
  background-color: var(--primary-color);
  color: white;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.control {
  border: 0.5px solid var(--background-dark-secondary);
  border-radius: 400px;
  outline: none;
  width: 100%;
  height: 10vh;
  padding: 0 4vh;
  background: var(--background-light-secondary);
  color: black;
  margin: 2vh 0;
  font-family: inherit;
  font-size: 2.8vh;
  transition: 0.4s;
}

button.control {
  background: var(--primary-color);
  border: 0px;
  color: white;
}
/* 
button:hover {
  background: var(--primary-color);
} */

.resetContainer {
  width: 100%;
  display: flex;
}

.reset-button {
  display: block;
  width: 100%;
  display: flex;
  margin-top: -10px;
  margin-left: 30px;
  color: var(--background-dark);
}

.welcome-card {
  background: var(--primary-color);
}

.welcome-card h4 {
  color: white;
}

.welcome-card p, .card-body p {
  text-align: center;
  font-size: 2vh;
}

.welcome-card .signup-button, .login-button {
  color: white;
  border: 2px solid white;
  border-radius: 200px;
  text-decoration: none;
  padding: 2.8vh 10vw;
  margin-top: 2vh;
  font-size: 2.8vh;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.welcome-card .signup-button:hover, .login-button:hover {
  background-color: white;
  color: var(--primary-color);
}

@media only screen and (max-height: 659px) {
  .card-container {
      align-items: flex-start;
  }
}

@media only screen and (max-width: 847px) {
  .card {
    width: 75%;
    min-height: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .control {
    font-size: 4.8vw;
  }

  .welcome-card {
    display: none;
  }

  .card.signup-card {
    overflow: scroll;
  }
}

@media only screen and (max-width: 500px) {
  .card-main-container {
      padding: 0px;
  }

  .card-wrapper {
      min-width: 100%;
      min-height: 100%;
  }

  .card.signup-card {
    overflow: scroll;
  }
  
  h4.signup-title {
    padding-top: 10vh;
  }

  .nameContainer {
    flex-direction: column;
  }

  .nameContainer .form-input.firstName {
  margin-right: 0px;
}

.nameContainer .form-input.lastName {
  margin-left: 0px;
}

}

@media only screen and (min-width: 1500px) {
  .card-wrapper {
      height: 100%;
  }
}





.bookingData {
  width: 100vw;
  height: 100%;
}


.table-container {
  /* background-color: #3a3b3c; */
  height: 100vh;
  padding: 50px;
}

#toggleModeButton {
  width: 44px;
  height: 44px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#toggleModeButton * > {
  width: 24px;
  height: 24px;
}



.stats {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}



.stats .card {
  align-items: start;
  margin-bottom: 20px;
  width: 30%;
}

.stats .card-body {
  width: 100%;
}

.action-buttons .new {
  margin-bottom: 10px;
}

.bookingTable{
  overflow-x:scroll;
}

.bookingTable .search-input {
  width: 100%;
  min-width: 100px;
}

@media only screen and (max-width: 547px) {
  .stats .card {
    width: 100%;
  }

  .table-container {
    /* background-color: #3a3b3c; */
    height: 100vh;
    padding: 10px;
  }
}

/* .stats > * {
  width:100%;
} */

























.tour-options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tour-options .tour-option:hover {
  background-color: none;
}

.tour-options .tour-option.selected {
  border: 3px solid #0077cc;
}

.tour-options .tour-option .card {
  width: 13rem;
}

.tour-options .card-body {
  width: 100%;
}

.tour-options .card-body.footer-body {
  padding: 0px;
}

.tour-options .card-body.footer-body .card-footer > * {
  margin: 5px;
}

@media only screen and (max-width: 991.98px) {
  .tour-options {
    flex-direction: column;
    align-items: center;
  }

  .tour-options .tour-option.btn {
    width: 100%;
    margin-bottom: 5px;
  }

  .tour-options .tour-option.btn .card {
    width: 100%;
  }
}





/* HOTEL */
.hotelContainer .caption h1 {
  font-family: 'GetShow', sans-serif;
  font-size: 58px;
  font-weight: 100;
  text-align: center;
}

.hotelContainer .caption h2 {
  font-size: 30px;
  text-align: center;
}

.hotelContainer .caption p {
  display: none;
}









.policies-container {
  padding: 20px;
  padding-top: 220px;
  background-color: #343a40; /* Dark background color */
  color: #dee2e6; /* Light text color for contrast */
}

.policies-content {
  max-width: 800px;
  margin: 0 auto;
}

.policies-content h1,
.policies-content h2 {
  color: #dee2e6; /* Dark text color */
}

.policies-content p {
  line-height: 1.6;
  color: #adb5bd; /* Slightly darker text color for readability */
}



.DateRangePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date-range-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial, sans-serif;
  color: var(--background-dark);
  width: 100%;
  margin: 0 auto;
}

.calendar {
  position: relative;
  width: 99%;
  border: 1px solid var(--background-dark-secondary);
  border-radius: 4px;
  background-color: var(--background-light);
  padding: 16px;
}

div.calendar-day.blocked.current-day {
  text-decoration: solid;
  text-decoration-line: underline;
  text-decoration-color: #ff4838;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}

.month-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

button.prevButton, button.nextButton {
  color: #fff;
  background-color: #ff4838;
  border-radius: 5px;
  padding: 5px;
}

button.prevButton:hover, button.nextButton:hover {
  background-color: #ff4838;
  border-radius: 5px;
  padding: 5px;
}

.weekday-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
}

.weekday-header > * {
  width: 52.28px;
  text-align: center;
}

.calendar-row {
  display: flex;
  justify-content: center;
  height: 40px;
}

.calendar-day {
  display: inline-block;
  width: 100%;
  /* width: calc(100% / 7); */
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: normal; /* added */
  margin: 0px;
}

.calendar-day:hover {
  background-color: var(--background-light-secondary);
}

.calendar-day.blocked {
  color: var(--blocked-color);
  text-decoration-line: line-through;
  text-decoration: line-through;
  cursor: not-allowed;
}

.calendar-day.selected {
  background-color: #ff4838;
  color: var(--background-light);
}

.calendar-day.in-range {
  background-color: #ff493822;
  color: #ff4838;
  border-radius: 10px;
  opacity: 1;
}

.confirm-request-button {
  position: relative;
  width: 100%;
  /* width: fit-content; */
  font-size: 1.3rem;
  font-weight: 700;
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  background-color: #ff4838;
  border: 2px solid #ccc;
  color: #fff;
  border-radius: 10px;
  transition: background-color 0.5s, color 0.5s;
}

/* .confirm-request-button:hover {
  background-color: var(--primary-dark-hover);
} */

.confirm-request-button:disabled {
  background-color: #28262663;
  color: #eaeaea;
  cursor: default;
}

.confirm-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.confirm-date {
  text-align: center;
}

.confirm-date.selected {
  color: green;
}

@media (max-height: 520px) {
  .date-range-picker {
    height: 80%;
  }
  .calendar {
  height: 200px;
  overflow-y: scroll;
  }

}

.first {
  background-color: red;
}

.second {
  background-color: green;
}

/* ********** */

.dateBlockerModal {
  width: 100%;
}

.dateBlockerModal .modal-content {
  background-color: var(--background-light);
  border-radius: 5px;
  padding: 0px;
  position: relative;
  width: 40%;
  max-width: 350px;
}

.dateBlocker {
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.dateBlocker form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.dateBlocker form input[type="date"] {
  width: 100%;
  max-width: 20rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
}

.dateBlocker form button[type="submit"] {
  width: 100%;
  max-width: 20rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.25rem;
  color: var(--background-light);
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dateBlocker form button[type="submit"]:hover {
  background-color: var(--secondary-hover);
}

.blockedDates {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 20rem;
  margin-bottom: 0.5rem;
}

.blockedDates span {
  margin-right: 1rem;
  font-size: 1rem;
}

.blockedDates button {
  padding: 0.5rem 1rem;
  background-color: var(--danger-color);
  border: none;
  border-radius: 0.25rem;
  color: var(--background-light);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.blockedDates button:hover {
  background-color: var(--danger-color-hover);
}

@media screen and (max-width: 768px) {
  .dateBlocker form input[type="date"] {
      max-width: 15rem;
    }

  .dateBlockerModal .modal-content {
    background-color: var(--background-light);
    border-radius: 5px;
    padding: 0px;
    position: relative;
    width: 90%;
  }
}


/* TourDetails.css */

/* Container styling */
.tour-details-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

/* Section styling */
.tour-details-overview,
.tour-details-highlights,
.tour-details-details,
.tour-details-important {
  margin-bottom: 30px;
}

/* Heading styling */
.tour-details-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

/* List styling */
.tour-details-highlights ul,
.tour-details-details ul {
  list-style-type: disc;
  margin-left: 20px;
}

/* Paragraph styling */
.tour-details-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}








.room-detail-container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
}

/* Heading styling */
.room-heading {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

/* Section styling */
.room-section {
  margin-bottom: 20px;
}

/* Subheading styling */
.room-subheading {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

/* Paragraph styling */
.room-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

/* Closing message styling */
.room-closing-message {
  font-style: italic;
  margin-top: 20px;
}

/* Highlighted text styling */
.room-highlight {
  color: #007bff;
  font-weight: bold;
}



.house-rules {
  padding-bottom: 80px;
  padding: 0px 20px 20px 20px;
}

.house-rules .container {
  height: fit-content;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  padding: 16px;
}

.house-rules .container .info {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.house-rules .container .info:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.house-rules .container .info .title {
  width: 30%;
}

.house-rules .container .info .detail {
  width: 70%;
}

.house-rules .container .info .detail p {
  margin-bottom: 5px;
}

